<div class="app-content content" *ngIf="isPageLoaded">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0" style="{{background}}">
                            <div class="card-header border-0" style="{{background}}">
                                <div class="card-title text-center">
                                    <img src="{{icon}}" alt="branding logo" width="35%" height="35%">
                                </div>
                            </div>
                            <div class="card-title text-center">
                                <h1 style="color:white; font-size: 1.9rem;">{{title}}</h1>
                            </div>
                            <div class="card-content">
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                </p>
                                <div class="card-body">

                                    <div>
                                        <!-- 言語 -->
                                        <fieldset class="form-group position-relative has-icon-left">

                                            <!-- <ifcs-select name="locale"-->
                                            <!--  [list]="localeList" bindLabel="localeName" bindValue="localeId"-->
                                            <!--  [(value)]="selectedLocaleId">-->
                                            <!--  </ifcs-select>-->

                                            <select class="form-control"
                                                    (change)=onChangeSelect($event)
                                                    [(ngModel)]="selectedLocaleId"
                                                    [ngClass]="{'safari-style': safariFlag === true}">
                                                <option *ngFor="let l of localeList" [value]="l.localeId">{{l.localeName}}</option>
                                            </select>

                                            <div class="form-control-position">
                                                <i class="la la-opera"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <!-- メールでログイン -->
                                    <div id="email" *ngIf="isEmailLogin">
                                        <!-- <div class="mb05 warning">ログインするため、メールアドレス・パスワードを入力してください。</div> -->
                                        <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" formControlName="email" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                    id="user-name" placeholder="{{'mailAddr' | translate}}" required>
                                                <div class="form-control-position">
                                                    <i class="la la-user"></i>
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required" translate>
                                                        {{'msg_inputMailAddr'}}</div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="password" placeholder="{{'password' | translate}}"
                                                    formControlName="password" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                                <div class="form-control-position">
                                                    <i class="la la-key"></i>
                                                </div>
                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.required" translate>
                                                        {{'msg_inputPassword'}}
                                                    </div>
                                                </div>

                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left"
                                                style="display: flex;">
                                                <input type="text" formControlName="checkcode" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.checkcode.errors }"
                                                    style="border-bottom-right-radius: 0%; border-top-right-radius: 0%;"
                                                    placeholder="{{'checkCode' | translate}}" required>
                                                <div class="form-control-position">
                                                    <i class="la la-lock"></i>
                                                </div>
                                                <div *ngIf="submitted && f.checkcode.errors" class="invalid-feedback">
                                                    <div *ngIf="f.checkcode.errors.required" translate>
                                                        <!-- チェックコードを入力してください -->
                                                        {{'inputCheckCode'}}
                                                    </div>
                                                </div>

                                                <!-- <div class="verifyDiv float-left"-->
                                                <!-- style="border-bottom-right-radius: 0.25rem; border-top-right-radius:  0.25rem;"-->
                                                <!-- (click)="checkCodeDraw()">-->
                                                <!-- <canvas #myCanvas class="verifyCanvas"></canvas>-->
                                                <!-- </div>-->
                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left">
                                                <ifcs-verify-code iid="verifyCode" #verifyCode></ifcs-verify-code>
                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left">
                                                <button type="submit" class="btn btn-outline-info btn-block">
                                                    <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                                    <i class="feather ft-unlock" *ngIf="!submitted"></i>
                                                    <span translate>&nbsp;login</span>
                                                </button>
                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left">
                                                <button class="btn btn-outline-info btn-block"
                                                    (click)="onPhoneNumberLoginClick()">
                                                    <i class="feather ft-phone"></i>
                                                    <span translate>&nbsp;loginByTelNo</span>
                                                </button>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <ifcs-badge label="{{'resendEmailVerify' | translate}}"
                                                    (clickEvent)="resendMail($event)"
                                                    linkClass="badge block badge-primary resendMail"
                                                    linkStyle="background-color: #ef9a9a"
                                                    iconClass="la icon-envelope-open font-medium-2">
                                                </ifcs-badge>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <ifcs-badge label="{{'resettingPassword' | translate}}"
                                                    (clickEvent)="restPassword($event)"
                                                    linkClass="badge block badge-primary restPassword"
                                                    linkStyle="background-color: #b388ff"
                                                    iconClass="la icon-bulb font-medium-2">
                                                </ifcs-badge>
                                            </fieldset>
                                        </form>
                                    </div> <!-- email -->

                                    <!-- 電話番号でログイン -->
                                    <div id="phone" *ngIf="!isEmailLogin">
                                        <!--     &lt;!&ndash; <div class="mb05 warning">ログインするため、携帯電話番号へ確認コードを送信してください。-->
                                        <!--    </div> &ndash;&gt;-->
                                        <!--    &lt;!&ndash; 言語 &ndash;&gt;-->
                                        <!--    <fieldset class="form-group position-relative has-icon-left">-->
                                        <!--    <div class="form-control-position">-->
                                        <!--     <i class="la la-opera"></i>-->
                                        <!--    </div>-->
                                        <!--    ifcs-select name="locale"-->
                                        <!--       [list]="localeList" bindLabel="localeName" bindValue="localeId"-->
                                        <!--    [(value)]="selectedLocaleId">-->
                                        <!--     </ifcs-select>-->
                                        <!--     </fieldset>-->
                                        <!--     -->

                                        <fieldset class="form-group position-relative has-icon-left mb15">
                                            <input type="text" class="form-control" [(ngModel)]="phoneNumber"
                                                placeholder="{{'mobileNumber' | translate}}" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                        </fieldset>

                                        <fieldset class="form-group has-icon-left mb15" style="display: flex;">
                                            <div style="flex: 1;">
                                                <button class="form-control btn btn-primary p0" (click)="onSendCode()"
                                                    [disabled]="phoneCheck" style="width: 100%; border-bottom-right-radius: 0%; border-top-right-radius: 0%;">
                                                    <span translate>{{'sendConfirmCode'}}</span>
                                                </button>
                                            </div>
                                            <div class="position-relative" style="flex: 1;">
                                                <input type="text" class="form-control" id="code" maxlength="6"
                                                    style="width: 100%; border-bottom-left-radius: 0%; border-top-left-radius: 0%;"
                                                    placeholder="{{'confirmCode' | translate}}" required
                                                    [(ngModel)]="phoneCode">
                                                <div class="form-control-position">
                                                    <i class="la la-lock"></i>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset class="form-group position-relative has-icon-left mb15">
                                            <button type="button" class="btn btn-outline-info btn-block"
                                                (click)="onPhoneNumberLogin()">
                                                <i class="fa fa-refresh fa-spin" *ngIf="phoneSubmit"></i>
                                                <i class="feather ft-unlock" *ngIf="!phoneSubmit"></i>
                                                <span translate>&nbsp;{{'login'}}</span>
                                            </button>
                                        </fieldset>

                                        <fieldset class="form-group position-relative has-icon-left">
                                            <button class="btn btn-outline-info btn-block"
                                                (click)="onEmailLoginClick()">
                                                <i class="feather ft-mail"></i>
                                                <span translate>&nbsp;{{'loginByMail'}}</span>
                                            </button>
                                        </fieldset>
                                    </div><!-- phone -->

                                </div> <!-- card-body -->

                                <!-- <div class="card-body">
                                    <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                            class="la la-user"></i> 新規登録</a>
                                </div> -->

                            </div> <!-- card-content -->
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>

<p-toast position="top-right" key="alertModal" baseZIndex="999"></p-toast>
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-secondary" icon="pi pi-exclamation-triangle"
                 acceptLabel="{{'confirm' | translate}}" rejectLabel="{{'cancel' | translate}}">
</p-confirmDialog>
