import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IfcsComponentBase, LanguageService} from "ifocus-lib";


@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.css']
})
export class HelpModalComponent extends IfcsComponentBase implements OnInit {

    @Input() helpId: string;
    @Input() title: string = "help";
    @Input() doc: string;
    @Input() fileName: string;

    constructor(
        language: LanguageService,
        translate: TranslateService,
    ) {
        super(language, translate);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
