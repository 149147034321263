<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
  [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
      (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <ul class="nav navbar-nav flex-row">
        <!-- Remove position relative in responsive -->
        <li class="nav-item mobile-menu d-lg-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs11"
            (click)="toggleNavigation($event)">
            <i class="feather ft-menu font-large-1"></i></a></li>
        <!-- dashboard-->
        <li class="nav-item mr-auto"><a [routerLink]="['/dashboard']" class="navbar-brand"
            routerLink="/dashboard/sales"><img class="brand-logo" alt="modern admin logo"
              [src]="_themeSettingsConfig.brand.logo.value">
            <h5 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h5>
          </a></li>

        <!-- メニューバーを自動的に隠す-->
        <li class="nav-item d-none d-md-block nav-toggle" title="{{'autoHideMenuBar'|translate}}">
          <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
            (click)="toggleFixMenu($event)">
            <i class="feather toggle-icon font-medium-3 white"
              [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu === 'collapse','ft-toggle-right': _themeSettingsConfig.menu === 'expand'}"></i>
          </a>
        </li>
        <li class="nav-item d-lg-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <!-- New-->
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <!-- 左側-->
        <ul class="nav navbar-nav mr-auto float-left">
          <!-- フルスクリーン表示-->
          <li class="nav-item d-none d-md-block" title="{{'showFullScreen'|translate}}">
            <a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()"
               *ngIf="maximize === 'on'">
              <i class="ficon feather ft-maximize"></i></a>
          </li>

          <!-- リフレッシュ-->
          <li class="nav-item d-none d-md-block" title="{{'refreshE'|translate}}">
            <a [routerLink]="" class="nav-link nav-link-expand" (click)="onRefresh()"
               *ngIf="maximize === 'on'">
              <i class="ficon feather icon-refresh"></i></a>
          </li>

          <!-- １０分毎に自動的なリフレッシュ-->
          <li class="nav-item d-none d-md-block" title="{{'refreshTenMin'|translate}}">
            <a class="nav-link nav-link-expand">
              <ui-switch size="small" [(ngModel)]="autoRefresh"
                         (valueChange)="onAutoRefresh($event)"></ui-switch>
            </a>
          </li>

          <!-- 戻る-->
          <li class="nav-item d-none d-md-block" title="{{'backPreviousPage'|translate}}">
            <a [routerLink]="" class="nav-link nav-link-expand" (click)="onBack()"
               *ngIf="maximize === 'on'">
              <i class="ficon la la-mail-reply"></i></a>
          </li>
        </ul>


        <!-- 右側-->
        <ul class="nav navbar-nav float-right">
          <!-- アラート-->
          <li *ngIf="topBarMenu.alert && topBarMenu.alert.isUse"
              class="dropdown-notification nav-item dropdown" ngbDropdown>
            <a class="nav-link nav-link-label" ngbDropdownToggle [routerLink]="[alertInfo.routerLink]"
               (click)="ngOnInit()" *ngIf="alertInfo.count === 0">
              <i class="ficon feather ft-alert-triangle" title="{{'alert'|translate}}"></i>
            </a>

            <ng-container *ngIf="alertInfo.count !== 0">
              <a class="nav-link nav-link-label" ngbDropdownToggle>
                <i class="ficon feather ft-alert-triangle"></i>
                <span class="badge badge-pill badge-danger badge-up badge-glow">{{alertInfo.count}}</span>
              </a>
              <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                <li class="dropdown-menu-header">
                  <h6 class="dropdown-header m-0"><span class="grey darken-2">Alerts</span></h6>
                  <span class="notification-tag badge badge-default badge-danger float-right m-0">
                                        {{alertInfo.count}} 新</span>
                </li>
                <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                    fxFlex="auto" [perfectScrollbar]="config">
                  <a href="javascript:void(0)" *ngFor="let item of alertInfo.list; index as idx">
                    <div class="media">
                      <div class="media-left align-self-center">
                        <i class="feather ft-alert-triangle icon-bg-circle bg-red bg-darken-1"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="media-heading red darken-1">{{item.title}}</h6>
                        <p class="notification-text font-small-3 text-muted">{{item.detail}}</p>
                        <small>
                          <time class="media-meta text-muted"
                                datetime="2015-06-11T18:29:20+08:00">{{item.timestamp}}
                          </time>
                        </small>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="dropdown-menu-footer">
                  <a class="dropdown-item text-muted text-center" href="javascript:void(0)"
                     [routerLink]="[alertInfo.routerLink]"><span
                          class="alert_span">Read all alerts</span></a>
                </li>
              </ul>
            </ng-container>
          </li>

          <!-- お知らせ-->
          <li *ngIf="notification === 'on' && topBarMenu.notification && topBarMenu.notification.isUse"
              class="dropdown-notification nav-item dropdown" ngbDropdown>
            <a class="nav-link nav-link-label" ngbDropdownToggle [routerLink]="[noticeInfo.routerLink]"
               [queryParams]="noticeInfo.queryParams" (click)="ngOnInit()" *ngIf="noticeInfo.count === 0">
              <i class="ficon feather ft-bell" title="{{'notice1'|translate}}"></i>
            </a>
            <ng-container *ngIf="noticeInfo.count !== 0">
              <a class="nav-link nav-link-label" ngbDropdownToggle>
                <i class="ficon feather ft-bell"></i>
                <span class="badge badge-pill badge-warning badge-up badge-glow">{{noticeInfo.count}}</span>
              </a>
              <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                <li class="dropdown-menu-header">
                  <h6 class="dropdown-header m-0"><span class="grey darken-2">Notifications</span>
                  </h6>
                  <span class="notification-tag badge badge-default badge-warning float-right m-0">
                                        {{noticeInfo.count}} 新</span>
                </li>
                <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                    fxFlex="auto" [perfectScrollbar]="config">
                  <a href="javascript:void(0)" *ngFor="let item of noticeInfo.list; index as idx">
                    <div class="media">
                      <div class="media-left align-self-center"><i
                              class="feather ft-bell icon-bg-circle bg-yellow bg-darken-3"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="media-heading yellow darken-3">{{item.title}}</h6>
                        <p class="notification-text font-small-3 text-muted">{{item.detail}}</p>
                        <small>
                          <time class="media-meta text-muted"
                                datetime="2015-06-11T18:29:20+08:00">{{item.timestamp}}
                          </time>
                        </small>
                      </div>
                    </div>
                  </a>

                </li>
                <li class="dropdown-menu-footer">
                  <a class="dropdown-item text-muted text-center" href="javascript:void(0)"
                     [routerLink]="[noticeInfo.routerLink]" [queryParams]="noticeInfo.queryParams">
                    <span class="notification_span">Read all notifications</span>
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>

          <!-- メッセージ送信-->
          <li *ngIf="topBarMenu.message && topBarMenu.message.isUse"
              class="dropdown-notification nav-item dropdown" ngbDropdown>
            <a class="nav-link nav-link-label" ngbDropdownToggle
               (click)="onSendMessageClick(myMessageModal)">
              <i class="ficon la la-bullhorn" title="{{'sendMessage'|translate}}"></i>
            </a>
          </li>

          <!-- ロケール-->
          <li *ngIf="topBarMenu.locale && topBarMenu.locale.isUse
                    && profileUser !== null && profileUser.loginUser && profileUser.loginUser.locale"
              class="dropdown-language nav-item" ngbDropdown>
            <a class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
              <i style="border: 1px solid #d0d0d0;" [ngClass]="profileUser.loginUser.locale.iconClass"></i>
              <span class="selected-language"></span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-flag"
                 *ngIf="localeInfo !== null && localeInfo.localeList.length > 0">
              <a class="dropdown-item"
                 *ngFor="let flg of localeInfo.localeList; index as idx"
                 (click)="onChangeLocale($event)">
                <i [ngClass]="flg.iconClass"></i>
                <span translate>{{flg.localeId}}</span>
              </a>
            </div>
          </li>

          <!-- ヘルプ-->
          <!-- <li *ngIf="topBarMenu.help && topBarMenu.help.isUse && isShowHelp"
              class="dropdown-notification nav-item dropdown" ngbDropdown>
            <a class="nav-link nav-link-label" ngbDropdownToggle (click)="onDownloadHelpFileForBar()">
              <i class="ficon feather ft-help-circle" title="{{'help'|translate}}"></i>
            </a>
          </li> -->

          <!-- プロファイル管理-->
          <li *ngIf="topBarMenu.user && topBarMenu.user.isUse"
              class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="profileUser" class="mr-1 user-name text-bold-700">{{profileUser.name}}</span>
              <span class="avatar avatar-online">
                                <img *ngIf="profileUser" [src]="profileUser.avatarUrl" class="imgWH" alt="avatar">
                                <i></i>
                            </span>
            </a>
            <div *ngIf="profileUser" ngbDropdownMenu class="dropdown-menu-right"
                 aria-labelledby="dropdownProfileMenu">
              <!-- プロファイル管理 -->
              <a *ngIf="topBarMenu.user.profile && topBarMenu.user.profile.isUse"
                 [routerLink]="[profileUser.routerLink]" class="dropdown-item">
                <i class="feather ft-user"></i>
                <span>{{'profileManage'|translate}}</span>
              </a>

              <!-- Webコンソールマニュアル -->
              <a *ngIf="topBarMenu.user.help && topBarMenu.user.help.isUse && isShowHelp"
                 class="dropdown-item" (click)="onDownloadHelpFile()">
                <i class="feather ft-help-circle"></i>
                <span>{{'webConsoleManual'|translate}}</span>
              </a>

              <!-- 端末マニュアル -->
              <a *ngIf="topBarMenu.user.manual && topBarMenu.user.manual.isUse && isShowHelp"
                 class="dropdown-item" (click)="onDownloadManualFile()">
                <i class="feather icon-info"></i>
                <span>{{'deviceManual'|translate}}</span>
              </a>

              <!-- 個人リフレッシュ -->
              <a *ngIf="topBarMenu.user.update && topBarMenu.user.update.isUse"
                 class="dropdown-item" (click)="onRefreshProfile()">
                <i class="feather ft-refresh-cw"></i>
                <span>{{'newProfile'|translate}}</span>
              </a>

              <!-- グループ -->
              <div ngbDropdown class="d-inline-block" *ngIf="topBarMenu.user.group && topBarMenu.user.group.isUse">
                <a class="btn-min-width dropdown-toggle group-div" ngbDropdownToggle>
                  <i class="feather ft-users"></i>
                  <span>{{'group'|translate}}</span>
                </a>
                <div ngbDropdownMenu *ngIf="profileUser !== null && profileUser.loginUser" class="dropdown-menu" >
                  <a class="dropdown-item"
                     *ngFor="let grp of profileUser.loginUser.groups; index as idx"
                     (click)="onGroupClick(grp.groupCode)">
                    <i class="feather"
                       [ngClass]="(profileUser.loginUser.group === grp.groupCode) ? 'ft-check-square': 'ft-square'"></i>
                    <span>{{grp.groupName}}</span>
                  </a>

                  <!--                                    <a class="dropdown-item">-->
                  <!--                                        <i class="feather ft-check-square"></i>-->
                  <!--                                        <span>Action - 1</span>-->
                  <!--                                    </a>-->
                  <!--                                    <a class="dropdown-item">-->
                  <!--                                        <i class="feather ft-square"></i>-->
                  <!--                                        <span>Another Action</span>-->
                  <!--                                    </a>-->
                  <!--                                    <a class="dropdown-item">-->
                  <!--                                        <i class="feather ft-square"></i>-->
                  <!--                                        <span>Something else is here</span>-->
                  <!--                                    </a>-->
                </div>
              </div>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="logout()">
                <i class="feather ft-power"></i>
                <span>{{'logout'|translate}}</span>
              </a>
            </div>
          </li><!-- プロファイル管理-->

        </ul>
      </div>
    </div>
    <!-- New-->
  </div>
</nav>

<p-toast position="top-right" key="alertModal" baseZIndex="999"></p-toast>

<!-- MY送信メッセージ一覧 -->
<ng-template #myMessageModal let-c="close" let-d="dismiss">
  <app-device-message-my [selectedRows]="selectedRows" [deviceSelDlg]="true" (closeEvent)="cancleModel()">
  </app-device-message-my>
</ng-template>


<!-- ヘルプダイアログ-->
<template *ngIf="topBarMenu.help && topBarMenu.help.isUse && topBarMenu.help.data">
  <app-help-modal helpId="heard_help_modal_bar"
                  [doc]="topBarMenu.help.data.fileUrl"
                  [fileName]="topBarMenu.help.data.fileName">
  </app-help-modal>
</template>

<template *ngIf="topBarMenu.user.help && topBarMenu.user.help.isUse && topBarMenu.user.help.data">
  <app-help-modal helpId="heard_help_modal"
                  title="{{'webConsoleManual'|translate}}"
                  [doc]="topBarMenu.user.help.data.fileUrl"
                  [fileName]="topBarMenu.user.help.data.fileName">
  </app-help-modal>
</template>

<template *ngIf="topBarMenu.user.manual && topBarMenu.user.manual.isUse && topBarMenu.user.manual.data">
  <app-help-modal helpId="heard_manual_modal"
                  title="{{'deviceManual'|translate}}"
                  [doc]="topBarMenu.user.manual.data.fileUrl"
                  [fileName]="topBarMenu.user.manual.data.fileName">
  </app-help-modal>
</template>
